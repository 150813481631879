// node modules
import React from 'react'
import { graphql, PageProps } from 'gatsby'

// layout
import Layout from 'components/layout/layout'
import Seo from 'components/layout/seo'

// hooks
import usePopup from 'hooks/usePopup'

// components
import Hero from 'components/pages/category-page/hero'
import Breadcrumbs from 'components/shared/breadcrumbs'
import Intro from 'components/pages/category-page/intro'
import Products from 'components/pages/category-page/products'
import Infobox from 'components/pages/category-page/infobox'
import About from 'components/pages/category-page/about'
import Contact from 'components/pages/category-page/contact'

// types
import { ProductThumbnail } from 'types/product'

const IndexPage: React.FC<PageProps<Queries.GoesPageQuery>> = ({ data }) => {
  const PAGE = data.wpPage?.goes
  const PAGE_SEO = data.wpPage?.seo!
  const PRODUCTS = data.allWpVehiclesArticle.nodes

  if (!PAGE || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  const GOES_VEHICLES: ProductThumbnail[] =
    PRODUCTS?.filter(
      (product) =>
        product?.produkt?.productOptionsProductName &&
        product?.produkt?.productOptionsProductImage
    )
      ?.map(({ date, slug, produkt }) => ({
        image: {
          src: produkt?.productOptionsProductImage?.localFile?.childImageSharp!
            ?.gatsbyImageData,
          alt: produkt?.productOptionsProductImage?.altText!,
        },
        name: produkt?.productOptionsProductName!,
        priceFrom: produkt?.productOptionsPriceFrom!,
        noPrice: Boolean(produkt?.productOptionsNoPrice),
        category: produkt?.productOptionsProductType!,
        dateAdded: date!,
        slug: slug!,
      }))
      .sort(
        (a, b) =>
          new Date(a.dateAdded).getTime() - new Date(b.dateAdded).getTime()
      ) || []

  const ABOUT_SECTIONS = [
    {
      image: {
        src: PAGE?.goesAdvImg1?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.goesAdvImg1?.altText!,
      },
      title: PAGE?.goesAdvSubtitle1!,
      description: PAGE?.goesAdvDescription1!,
    },
    {
      image: {
        src: PAGE?.goesAdvImg2?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.goesAdvImg2?.altText!,
      },
      title: PAGE?.goesAdvSubtitle2!,
      description: PAGE?.goesAdvDescription2!,
    },
    {
      image: {
        src: PAGE?.goesAdvImg3?.localFile?.childImageSharp?.gatsbyImageData!,
        alt: PAGE?.goesAdvImg3?.altText!,
      },
      title: PAGE?.goesAdvSubtitle3!,
      description: PAGE?.goesAdvDescription3!,
    },
  ]

  const POPUP = {
    image: {
      src: PAGE?.goesPopupImg?.localFile?.childImageSharp?.gatsbyImageData!,
      alt: PAGE?.goesPopupImg?.altText ?? '',
    },
    delay: PAGE?.goesPopupDelay,
    link: PAGE?.goesPopupLink ?? null,
    visibleOnMobile: PAGE?.goesPopupVisibleOnMobile ?? false,
  }

  usePopup(POPUP)

  return (
    <Layout>
      <Seo
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Hero
        heading={PAGE?.goesBannerTitle!}
        image={{
          src: PAGE?.goesBannerHeroImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.goesBannerHeroImg?.altText!,
        }}
      />
      <Breadcrumbs crumbs={[{ name: data?.wpPage?.title! }]} />
      <Intro
        heading={PAGE?.goesDescriptionTitle!}
        text={PAGE?.goesDescriptionText!}
      />
      {GOES_VEHICLES.length > 0 && <Products products={GOES_VEHICLES} />}
      <Infobox
        heading={PAGE?.goesSeparatorText!}
        image={{
          src: PAGE?.goesSeparatorImg?.localFile?.childImageSharp
            ?.gatsbyImageData!,
          alt: PAGE?.goesSeparatorImg?.altText!,
        }}
      />
      {ABOUT_SECTIONS.length > 0 && (
        <About heading={PAGE?.goesAdvTitle!} sections={ABOUT_SECTIONS} />
      )}
      <Contact
        heading={PAGE?.goesContactTitle!}
        text="Skontaktuj się z nami"
        phone={PAGE?.goesContactPhoneNumber!}
        email={PAGE?.goesContactEmail!}
      />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query GoesPage {
    wpPage(slug: { regex: "/goes/" }) {
      title
      goes {
        goesBannerTitle
        goesDescriptionTitle
        goesDescriptionText
        goesSeparatorText
        goesAdvTitle
        goesAdvSubtitle1
        goesAdvSubtitle2
        goesAdvSubtitle3
        goesAdvDescription1
        goesAdvDescription2
        goesAdvDescription3
        goesContactTitle
        goesContactEmail
        goesContactPhoneNumber
        goesBannerHeroImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 1920
                placeholder: DOMINANT_COLOR
                breakpoints: [1280, 1920]
              )
            }
          }
        }
        goesSeparatorImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1920)
            }
          }
        }
        goesAdvImg1 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        goesAdvImg2 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        goesAdvImg3 {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 1250)
            }
          }
        }
        goesPopupImg {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        goesPopupDelay
        goesPopupLink
        goesPopupVisibleOnMobile
      }
      seo {
        ...WpSEO
      }
    }
    allWpVehiclesArticle(
      filter: { produkt: { productOptionsProductType: { eq: "Goes" } } }
    ) {
      nodes {
        date
        slug
        produkt {
          productOptionsProductType
          productOptionsProductName
          productOptionsPriceFrom
          productOptionsNoPrice
          productOptionsProductImage {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(width: 700)
              }
            }
          }
        }
      }
    }
  }
`
